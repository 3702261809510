<template>
  <div>
    <template v-if="getUser.Telegram">
      <v-card>
        <v-card-title>
          <span class="text-h6">RÚT TIỀN</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="auto"
            >
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="green--text"><strong>Số dư:</strong> {{ DoiTien(balance) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="red--text"><strong>Đóng băng:</strong> {{ DoiTien(freeze) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="orange--text"><strong>Chờ duyệt:</strong> {{ DoiTien(pending) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-card flat>
                      <v-card-text>
                        <v-currency-field
                          v-model="sotien"
                          label="Số tiền muốn rút"
                          :hint="MaxAmount"
                          persistent-hint
                        />
                        <v-btn
                          color="green"
                          x-small
                          @click="FillMax"
                        >
                          Số tiền tối đa
                        </v-btn>
                        <br>
                        <v-textarea
                          v-model="ghichu"
                          label="Ghi chú"
                          hint="Không có nhu cầu thì bỏ trống. Có nhu cầu ví dụ như donate cho a Bơ đẹp trai vài củ thì ghi vào"
                          persistent-hint
                        />
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          color="green"
                          @click="doWithdraw"
                          :disabled="sotien < 100000"
                        >
                          Rút tiền
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template v-else>
      <span class="text-h5 red--text pa-6">
        KÍCH HOẠT TÀI KHOẢN TELEGRAM TRƯỚC KHI RÚT TIỀN
      </span>
      <br />
      <span class="text-h6 pa-2">Hướng dẫn: </span>
      <br />
      <p class="white--text pa-4">1.<a href="https://t.me/vincent_baby_bot?text=/verify" class="white--text" target="_blank">Bấm vào link để chat với Bot</a></p>
      <p class="white--text pa-4">2. Gõ <code>/verify {{getUser._id}}</code></p>
    </template>
    <loading :status="loading" />
  </div>
</template>
<script>
import Header from '@/components/base/pagehead.vue'
import loading from '@/components/base/loading.vue'
import MyAccount from '@/modules/my.js'
import {ThongBao, DoiTien} from '@/helpers'
import {mapGetters} from 'vuex'

export default {
  components:{
    Header,
    loading,
  },
  data () {
    return {
      loading: true,
      sotien: 0,
      ghichu: '',
      max:0,
      balance:0,
      freeze: 0,
      pending: 0,
    }
  },
  computed:{
    ...mapGetters(['getUser']),
    MaxAmount(){
      return `Số tiền tối đa có thể rút: ${this.DoiTien(this.max)}`
    },
  },
  methods:{
    ...MyAccount,
    DoiTien,
    FillMax(){
      console.log(this.max);
      this.sotien = this.max;
    },
    callback(){
      this.loading = false;
    },
    doWithdraw(){
      if(this.sotien > this.max) return ThongBao.Error('Số tiền rút vượt quá mức cho phép !!!')
      this.loading = true;
      this.Withdraw({sotien:this.sotien,ghichu:this.ghichu}).finally(this.callback)
    },
    doGetBalance(){
      this.Balance().then(resp=>{
        const {balance,freeze,pending} = resp
        this.balance = parseInt(balance); this.freeze = parseInt(freeze); this.pending = parseInt(pending);
        const max = parseInt(balance)-parseInt(freeze)-parseInt(pending)
        this.max = (max > 0) ? max : 0;
      }).finally(this.callback)
    }
  },
  mounted(){
    this.doGetBalance()
  }
}
</script>

<style>

</style>